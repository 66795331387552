import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 74",
  week: "Semana 11",
  day: "26",
  month: "May",
  monthNumber: "05",
  date: "2020-05-26",
  path: "/cronologia/semana-11#dia-26-may/",
};

const Day74 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          <strong>El gobierno ha declarado 10 días de luto oficial</strong>{" "}
          (desde mañana 27 de mayo hasta el próximo 6 de junio) por las personas
          fallecidas por coronavirus.
        </ModText>

        <ModText>
          Para seguir paliando los efectos de COVID-19, el Gobierno ha aprobado
          un nuevo{" "}
          <strong>
            paquete de medidas agrarias, científicas, económicas, de empleo y
            Seguridad Social
          </strong>
          . Por un lado, se han prorrogado algunas de las de las normas
          decretadas en las últimas semanas, y por otro, se han establecido
          nuevas medidas para diferentes sectores.
        </ModText>
        <ModText>
          Entre las acciones que van a llevar se a cabo, podemos encontrar:
        </ModText>
        <ModDotList>
          Medidas tributarias para pymes, autónomos y sociedades
        </ModDotList>
        <ModDotList>
          Medidas para trabajadores del campo y personal sanitario
        </ModDotList>
        <ModDotList>Aportación extraordinaria a la Seguridad Social</ModDotList>
        <ModDotList>Reforzar la investigación para una vacuna</ModDotList>

        <ModText>
          Por otra parte, y con la finalidad de garantizar el acceso a la
          prestación extraordinaria por desempleo al colectivo de{" "}
          <strong>artistas</strong>, el Consejo de Ministros ha aprobado la{" "}
          <strong>simplificación de los requisitos</strong>
          necesarios para poder acogerse a esta prestación durante la crisis
          sanitaria.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/consejodeministros/Paginas/enlaces/260520-enlace-rd.aspx"
            name="Resoluciones del consejo de ministros 26 Mayo 2020"
          />
          <ReferenceRow
            link="https://www.culturaydeporte.gob.es/dam/jcr:bf9b9c42-9be9-4d38-9224-55a3dc0ee2f4/recomendaciones-mcd-bibliotecas-fases-0-1-y-2.pdf"
            name="Recomendaciones para bibliotecas"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day74;
